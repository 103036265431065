import { gql } from 'urql'
export const FragPartnerInfoPageSection = gql`
  fragment PartnerInfoPageSection on PartnerInfoPageSection {
    content {
      name
      slug
      title
      description
      logo
      showVideo
      helenEnabled
      code
      couponCode
      userStats {
        formattedActiveDays
        formattedTotalBookings
        formattedTotalMinutes
        isActiveUser
        isNewUser
        activeDays
        totalBookings
        totalMinutes
      }
      video {
        srcUrl
        posterUrl
      }
      primaryAction {
        url
        label
      }
      navigationActions {
        label
        url
      }
    }
  }
`
